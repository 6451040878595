import { Options, Vue } from 'vue-class-component'
import { Action, Getter, Mutation, State, namespace } from 'vuex-class'
import { WorldsWebModels } from '@/store/worldsWebModels/types';
import { FilterModelChoise } from '@/components/pages/worlds-web-models/model-choice/worlds-web-models-model-choice.interface'
import {filter} from "minimatch";
import {getLocalStorageService, getSessionStorageService, setLocalStorageService} from '@/services/storage.service'
import * as lang from "@/store/lang.js"
import $ from "jquery";

const page = namespace('worldsWebModels');
const model = namespace('modelAccount');

export default class WorldsWebModelsModelChoicePage extends Vue {
    @page.State('worldsWebModels') worldsWebModels: WorldsWebModels | undefined;
    @page.Action('getAllWebModels') getAllWebModels: any;
    @page.Getter('getArrWebModels') getArrWebModels: any;
    @model.Action('getFormValues') getFormValues: any;
    @model.Getter('getFormValues') formValues: any;
    @model.Action('getAllTags') getAllTags: any;
    @model.Getter('getModelSettingsTags') tags: any;
    @page.Getter('paginationInfo') paginationInfo: any;

    lang: any = "";
    pageNum: number = 1;

    //Фильтры
    filterQueryString: string = "";
    filterNickname: string = "";
    filtersAge: any = [
        {
            selected: false,
            min: 18,
            max: 29
        },
        {
            selected: false,
            min: 30,
            max: 39
        },
        {
            selected: false,
            min: 40,
            max: 49
        },
        {
            selected: false,
            min: 50,
            max: "∞"
        },
    ];
    filterNew = false;
    filterPairs = true;
    filterSingle = true;
    filterWithBoys = false;
    filters: any = [];

    models: any = [];

    UpdateModelsList() {
        let _this = this;

        this.getAllWebModels(this.filterQueryString).then(function () {

        });
    }

    IsNewStream(startTime : any)
    {
        return (Date.now()/1000 - startTime) < 60;
    }

    ChangeFilter(filter: any, resetPage = true) {
        if (filter)
            filter.selected = filter.selected ? false : true;

        if (resetPage)
            this.pageNum = 1;

        this.filterQueryString = "?";//?a[]=1&a[]=2&a[]=3

        //Базовые фильтры
        for (let filterName in this.filters) {
            for (let filter of this.filters[filterName]) {
                if (filter.selected) {
                    this.filterQueryString += filterName + "[]=" + filter.value + "&";
                }
            }
        }

        //Возраст
        for (let filter of this.filtersAge) {
            if (filter.selected) {
                this.filterQueryString += "age[]=" + filter.min + "&";

                if (filter.max != "∞")
                    this.filterQueryString += "age[]=" + filter.max + "&";
            }
        }

        //Новые
        if (this.filterNew)
            this.filterQueryString += "new=1&";

        //Никнейм
        if (this.filterNickname.length > 1)
            this.filterQueryString += "nickname=" + this.filterNickname + "&";

        //Пагинация
        if (this.pageNum > 1)
            this.filterQueryString += "page=" + this.pageNum + "&";


        this.filterQueryString = this.filterQueryString.slice(0, -1);

        this.UpdateModelsList();
        console.log(this.filterQueryString);
    }

    ToPage(pageNum: number) {
        this.pageNum = pageNum;
        this.ChangeFilter(null, false);
    }

    NextPage()
    {
        this.pageNum++;
        if (this.pageNum > this.paginationInfo.totalPages)
            this.pageNum = this.paginationInfo.totalPages;

        this.ChangeFilter(null, false);
    }

    ToPageTarget()
    {
        let page = parseInt(<any>$('#js-to-page').val());
        if (page && page > 0 && page <= this.paginationInfo.totalPages)
        {
            this.pageNum = page;
            this.ChangeFilter(null, false);
        }
    }

	mounted() {
        this.filters = {};
		var _this = this;

        this.lang = lang.GetLang();

        this.UpdateModelsList();
        setInterval(this.UpdateModelsList, 5000);

        this.getFormValues().then(function () {
            _this.filters.ethnic_origin = [];
            _this.formValues.ethnic_origin.forEach(function(data : any) {
                var filter = {
                    label : data.label,
                    value : data.value,
                    selected : false,
                };

                _this.filters["ethnic_origin"].push(filter);
            });

            _this.filters.figure = [];
            _this.formValues.figure.forEach(function(data : any) {
                var filter = {
                    label : data.label,
                    value : data.value,
                    selected : false,
                };
                _this.filters.figure.push(filter);
            });
        });

        this.getAllTags().then(function () {
            _this.filters.tags = [];
            _this.tags.forEach(function(data : any) {
                var filter = {
                    label : data.name,
                    value : data.id,
                    selected : false,
                };
                _this.filters.tags.push(filter);
            });
        });
	}
}
